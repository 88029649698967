import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('newsbroadcastplayer', ({
  jwpMediaId,
  title,
  autoplay,
  hasClips,
  clipList,
  image,
}) => ({
  jwpMediaId: jwpMediaId,
  title: title,
  autoplay: autoplay,
  hasClips: hasClips,
  clipList: clipList,
  image: image,
  displaySideBar: true,
  player: null,
  init () {
    let startingHash = window.location.hash.substring(1);
    if (startingHash.length > 0) {
      let candidate = this.clipList.find(e => e.jwp_media_id === startingHash);
      if (candidate) {
        this.title = candidate.title;
        this.jwpMediaId = candidate.jwp_media_id;
      }
    }
    this.autoplay = this.autoplay === true;
    requestAnimationFrame(() => {
      const opts = this.jwpOptions();
      this.player = jwplayer(this.$refs.video).setup(opts);
    })
  },

  jwpOptions() {
    let opts = {
      // playlist: this.playlistUrl(),
      width: '100%',
      height: '100%',
      aspectratio: '16:9',
      autostart: this.autoplay,
      mute: false,
      autoPause: {
        viewability: false,
      },
      displaytitle: false,
      displayHeading: false,
      displaydescription: false,
    }

    const baseUrl = 'https://cdn.jwplayer.com';

    if (this.image) {
      opts.file = `${baseUrl}/manifests/${ this.jwpMediaId }.m3u8`;
      opts.image = `${this.image}`;
    } else {
      opts.playlist = `${baseUrl}/v2/media/${ this.jwpMediaId }?format=json`;
    }
    return opts;
  },
  toggleSideBar () {
    this.displaySideBar = !this.displaySideBar;
  },
  playlistUrl () {
    return `https://cdn.jwplayer.com/v2/media/${ this.jwpMediaId }?format=json`;
  },
  loadVideo (jwpMediaId, title) {
    this.jwpMediaId = jwpMediaId;
    this.title = title;
    this.player.image = this.image;
    this.player.load(this.playlistUrl());
    this.player.play();
    this.player.setMute(false);
    this.scrollToPlayer();
    window.location.hash = jwpMediaId;
  },
  scrollToPlayer() {
    if (window.innerWidth < 768) {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 100);
    }
  }
}));